import { effect, inject, Injectable, Injector, signal } from '@angular/core';
import { LocalStorageService } from '../utilities/local-storage.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class State {
  private key = 'store';
  private _localStorageService = inject(LocalStorageService);

  storeBehaviorSubject = new BehaviorSubject<StoreType>(initialStoreState);
  store = toSignal(this.storeBehaviorSubject.asObservable());

  constructor(private injector: Injector) {
    const localStore = this._localStorageService.getItem<StoreType>(this.key);
    if (localStore) this.storeBehaviorSubject.next(localStore);

    effect(() => {
      // console.log(`State updated: `, this.store());
      this._localStorageService.setItem(this.key, this.store());
    });
  }
}

export interface StoreType {
  loading: boolean;
  error: string;
  lastWorkingPath: string;
  subPortfolioId?: number;
  portfolioId?: number;
  clientId?: number;
}

const initialStoreState: StoreType = {
  loading: false,
  lastWorkingPath: '',
  error: ''
};
