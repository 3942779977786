import { inject, Injectable } from '@angular/core';
import { State, StoreType } from './state';

@Injectable({
  providedIn: 'root'
})
export class Actions {
  private _stateService = inject(State);

  constructor() {}

  get currentStore() {
    return (this._stateService.store() || {}) as StoreType;
  }

  private updateStore = (updatedStore: StoreType) => {
    this._stateService.storeBehaviorSubject.next({ ...updatedStore });
  };

  setStore(newStore: StoreType) {
    this.updateStore({ ...this.currentStore, ...newStore });
  }

  setSubPortfolioId(subPortfolioId?: number) {
    this.updateStore({ ...this.currentStore, subPortfolioId });
  }

  setPortfolioId(portfolioId: number) {
    this.updateStore({ ...this.currentStore, portfolioId });
  }

  setClientId(clientId?: number) {
    this.updateStore({ ...this.currentStore, clientId });
  }

  // with side effect because this is with asynchronous call
  async fetchUserPrefs() {
    this.enableLoading();
    try {
      //   const { data } = await this._httpService.get<UserPrefs[]>('user-prefs');
      //   this._stateService.store.update((store) => {
      //     store.userPrefs = data;
      //     return store;
      //   });
    } catch (e: any) {
      this.setError(e.message);
    }
    this.disableLoading();
  }

  // with no side effect because this has no asynchronous call
  //   removeTodoById(index: number) {
  //     this._stateService.store.update((state) => {
  //       state.todos.splice(index, 1);
  //       return state;
  //     });
  //   }

  //   async createUserPref(value: UserPref) {
  //     this.enableLoading();
  //     try {
  //       const { data } = await this._httpService.post<Post>('posts', value);
  //       this._stateService.store.update((state) => {
  //         state.posts.push(data);
  //         return state;
  //       });
  //     } catch (e: any) {
  //       this.setError(e.message);
  //     }
  //     this.disableLoading();
  //   }

  private enableLoading() {
    this.updateStore({ ...this.currentStore, loading: true, error: '' });
  }

  private disableLoading() {
    this.updateStore({ ...this.currentStore, loading: false });
  }

  private setError(error: string) {
    this.updateStore({ ...this.currentStore, error });
  }
}
