import { computed, inject, Injectable } from '@angular/core';
import { State, StoreType } from './state';

@Injectable({
  providedIn: 'root'
})
export class Getters {
  private _stateService = inject(State);

  get store() {
    return (this._stateService.store() || {}) as StoreType;
  }

  subPortfolioId = computed(() => this.store.subPortfolioId);
  portfolioId = computed(() => this.store.portfolioId);
  clientId = computed(() => this.store.clientId);
}
